<template>
  <div>
    <section class="section-our-story">
      <div class=" mx-auto text-center">
        <img :src="require('@/assets/img/landings/why-us/roxie-big-logo.png')" alt="roxie logo" class="section-content__main-img">
        <div class="section-content__info">
          <p class="section-content__nice-text">
            <span class="blue-marker">Our Know-How + Your Business Verve</span><br>
            <span class="black-marker">=</span><br>
            <span class="red-marker">A Perfect Match</span>
          </p>
          <div class="section-content__description">
            <h2 class="section-content__headline">Our Mission</h2>
            <p class="section-content__paragraph">
              Roxie was founded to combine our jewelry business savvy and quality
              products with your entrepreneurship and desire to expand your jewelry
              business.
            </p>
            <p class="section-content__paragraph">
              We are here to help you move your jewelry business forward by
              providing you with a seamless jewelry e-store platform and top-notch, curated
              fine jewelry.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="section-our-style px-sm-16">
      <div class="section-content mx-auto">
        <div class="text-center section-our-style-img px-4 px-sm-0">
          <img :src="require('@/assets/img/landings/why-us/jewelry-mockup.png')" alt="">
          <img :src="require('@/assets/img/landings/why-us/curated_timeless.png')" alt="">
        </div>
        <div class="section-our-style__text1">
          <h2 class="section-our-style__headline">
            Authentic<br> New York Style
            <span class="marker-mark--blue">Design</span>
          </h2>
          <p class="section-our-style__text2">
            We are New York by design. Roxie is proud to be a part of the bustling <span>NYC jewelry
            scene.</span>
          </p>
          <p class="section-our-style__text2">But our fresh designs, high-quality products, and customer support set us apart
            from the rest.</p>
        </div>
      </div>
    </section>

    <section class="section-our-quality px-sm-16">
      <div class="section-content mx-auto">
        <div class="text-center section-our-style-img px-4 px-sm-0">
          <img :src="require('@/assets/img/landings/why-us/jewelry-machine.png')" alt="">
          <img :src="require('@/assets/img/landings/why-us/geniune_shine.png')" alt="">
        </div>
        <div class="section-our-quality__text1">
          <h2 class="section-our-quality__headline">
            <span class="marker-mark--blue">Premium</span><br>
            <span class="base-text">fine jewelry</span>
          </h2>
          <p class="section-our-quality__text2"><span>Providing you with unparalleled quality is our motto.</span></p>
          <p class="section-our-quality__text2">
            We ensure that every
            jewelry piece is exceptional by using the highest-grade materials and superior
            craftsmanship.
          </p>
        </div>
      </div>
    </section>

    <section class="section-instr">
      <div class="section-content d-flex align-center justify-center">
        <div class="section-instr--left d-flex justify-center align-center">
          <span class="white--text marker-mark--font text-center">Concept</span>
        </div>
        <img :src="require('@/assets/img/icons/ic-arrow-right-solid-black.svg')" alt="">
        <div class="section-instr-item d-flex justify-center align-center">
          <span class="white--text marker-mark--font text-center">Ready to <br> Market</span>
        </div>
      </div>
    </section>

    <section class="section-benefits">
      <h2 class="section-header text-center">
        Still not convinced? <br>
        We have plenty of other reasons!
      </h2>

      <div class="section-content d-flex flex-wrap justify-center">
        <div class="benefits benefits--1">
          <div>
            <img :src="require('@/assets/img/landings/why-us/artwork1.png')" alt="">
          </div>
          <div>
            <h2>Customer-Driven</h2>
            <p>
              Our mission is to put your needs at the center of everything
              we do.
              We pay attention to what you need and help you get there.
            </p>
          </div>
        </div>
        <div class="benefits benefits--2">
          <div>
            <img :src="require('@/assets/img/landings/why-us/artwork2.png')" alt="">
          </div>
          <div>
            <h2>Personalized</h2>
            <p>
              Our bespoke jewelry speaks to a wide range of customers and
              provides the highest level of customization.
            </p>
          </div>
        </div>
        <div class="benefits benefits--1">
          <div>
            <img :src="require('@/assets/img/landings/why-us/artwork3.png')" alt="">
          </div>
          <div>
            <h2>Stylish</h2>
            <p>
              We work hard to provide trending jewelry pieces for a variety of tastes.
            </p>
          </div>
        </div>
        <div class="benefits benefits--2">
          <div>
            <img :src="require('@/assets/img/landings/why-us/artwork4.png')" alt="">
          </div>
          <div>
            <h2>Reliable</h2>
            <p>
              Our clients and their customers agree—Roxie has a history of customer satisfaction. See what they have to say
              <router-link :to="{name: 'MainPageLanding', hash: '#testimonials'}" class="cochin-lt--font">here</router-link>!
            </p>
          </div>
        </div>
        <div class="benefits benefits--1">
          <div class="benefits__image">
            <img :src="require('@/assets/img/landings/why-us/artwork5.png')" alt="">
          </div>
          <div>
            <h2>Innovative</h2>
            <p>
              At Roxie, we are dedicated to helping you find new ways to reach
              higher profits and growth rates for your business.
            </p>
          </div>
        </div>
        <div class="benefits benefits--2">
          <div>
            <img :src="require('@/assets/img/landings/why-us/artwork6.png')" alt="">
          </div>
          <div>
            <h2>Quality-Focused</h2>
            <p>
              Our standards are exceptionally high. When crafting our jewelry, we adhere to the strictest quality guidelines to provide you with top-notch, lasting, genuine pieces.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="section-reach-clients">
      <div class="section-content mx-auto">
        <h2 class="section-header">
          <span class="marker-mark--font light-blue--text first-headline">Let Roxie</span>
          <b class="dm-serif-display--font font-weight-regular">help you</b>
          <span class="marker-mark--font light-blue--text">Reach</span>
          <b class="dm-serif-display--font font-weight-regular second-text">your customers <br> and meet your goals.</b>
        </h2>
        <img :src="require('@/assets/img/landings/why-us/girl.png')" alt="">
      </div>
    </section>

  </div>
</template>

<script>
export default {
  name: 'WhyUsLanding',
  data: () => ({
    benefits: [
      {
        name: 'Customer-Driven.',
        text: 'Our mission is to put your needs at the center of everything we do. We pay attention to what you need and help you get there.'
      },
      {
        name: 'Stylish.',
        text: 'We work hard to provide trending jewelry pieces for a variety of tastes.'
      },
      {
        name: 'Innovative.',
        text: 'At Roxie, we are dedicated to helping you find new ways to reach higher profits and growth rates for your business.'
      },
      {
        name: 'Personalized.',
        text: 'Our bespoke jewelry speaks to a wide range of customers and provides the highest level of customization.'
      },
      {
        name: 'Quality-Focused.',
        text: 'Our standards are exceptionally high. When crafting our jewelry, we adhere to the strictest quality guidelines to provide you with top-notch, lasting, genuine pieces.'
      },
      {
        name: 'Reliable.',
        text: 'Our clients and their customers agree—Roxie has a history of customer satisfaction.'
      },
    ]
  }),
}
</script>
