<template>
  <div class="product-page pt-12">
    <v-tabs
      v-model="tabs"
      centered
      class="product-page-tabs mb-8"
    >
      <v-tab href="#tab-1">
        The minimalist theme
      </v-tab>
      <v-tab href="#tab-2">
        The modern theme
      </v-tab>
    </v-tabs>

    <div :class="{'vertical-theme' : tabs === 'tab-1'}">
      <!-- Please Add/Update the company_product_id in the below given hidden input field -->
      <div class="configurator-main" id="vertical-flight-template"></div>
      <div class="configurator-main" id="horizontal-slide-template"></div>
      <input id="item-id" type="hidden" :value="$route.params.id"/>
    </div>

    <div class="product-details d-flex flex-column flex-sm-row align-start justify-center mt-12 mt-sm-16 mx-auto pt-5 px-4 px-sm-8">
      <h3 class="py-2 px-6 flama-bold--font font-italic">AVAILABLE IN:</h3>
      <div class="product-details-item flex-shrink-0 metals mb-2">
        <h6 class="mb-5 flama-bold--font font-italic primary--text">Metals:</h6>
        <div class="d-flex align-center mb-4" v-for="(metal, idx) in metals" :key="idx">
          <img :src="require(`@/assets/img/landings/project-item-page/${metal.img}.png`)" :alt="metal.name" class="mr-4">
          <span>{{ metal.name }}</span>
        </div>
      </div>
      <div class="product-details-item flex-shrink-0 stones mb-3">
        <h6 class="mb-5 flama-bold--font font-italic primary--text">Stones:</h6>
        <div class="d-flex flex-wrap">
          <div class="stone-item d-flex flex-column align-center justify-start mb-3 text-center" v-for="(stone, idx) in stones" :key="idx">
            <img :src="require(`@/assets/img/landings/project-item-page/${stone.img}.png`)" :alt="stone.name" class="mb-1">
            <span v-html="stone.name"></span>
          </div>
        </div>
      </div>
      <div class="product-details-item flex-shrink-0 sizes mb-6">
        <h6 class="mb-5 flama-bold--font font-italic primary--text">Sizes</h6>
        <p class="mb-0 text-uppercase" v-if="category">{{ availableSizes[category.name] }}</p>
      </div>
      <div class="product-details-item flex-shrink-0 other mb-6">
        <div>
          <h6 class="mb-5 flama-bold--font font-italic primary--text">Shipping:</h6>
          <p class="mb-1">Standard Shipping: <span class="flama-bold--font font-italic">$5</span></p>
          <p class="mb-0">Expedited Shipping: <span class="flama-bold--font font-italic">$35</span></p>
        </div>
        <div>
          <h6 class="mb-2 flama-bold--font font-italic primary--text">Production Time</h6>
          <p class="mb-0 text-uppercase">7-14 days</p>
        </div>
      </div>
    </div>

    <FAQ></FAQ>
  </div>
</template>

<script>
import FAQ from "@/components/landings/FAQ";

export default {
  name: 'ProductItemPage',
  components: {
    FAQ,
  },
  data: () => ({
    tabs: null,
    product: null,
    category: null,
    metals: [
      {name: '10K/14K Yellow Gold', img: 'yellow-gold'},
      {name: '10K Rose Gold', img: 'rose-gold'},
      {name: '10K White Gold', img: 'white-gold'},
      {name: 'Silver', img: 'silver'},
    ],
    stones: [
      {name: 'CR. Pink <br> Sapphire', img: 'pink-sapphire'},
      {name: 'Amethyst', img: 'amethyst'},
      {name: 'Aquamarine', img: 'aquamarine'},
      {name: 'Blue <br> Topaz', img: 'blue-topaz'},
      {name: 'Citrine', img: 'citrine'},
      {name: 'Alexandrite', img: 'alexandrite'},
      {name: 'CR. Blue <br> Saphire', img: 'blue-sapphire'},
      {name: 'CR. <br> Emerald', img: 'emerald'},
      {name: 'CR. Ruby', img: 'ruby'},
      {name: 'Garnet', img: 'garnet'},
    ],
    availableSizes: {
      'Rings': '3.5 — 12',
      'Pendants': '16”, 18”',
    }
  }),
  async created() {
    await this.$store.dispatch("LOAD_CATEGORIES");
    await this.$store.dispatch('LOAD_PRODUCTS');
    this.product = this.$store.state.products.allProducts.find(product => product.company_product_id === this.$route.params.id);
    this.category = this.$store.state.products.allCategories.find(category => category.id === this.product.category_id);
    document.title = this.product.name;
    if (this.$route.hash.includes('#tab-')) {
      this.tabs = this.$route.hash.replace('#', '');
    }
  },
  mounted() {
    this.createScriptNode('https://joinroxie.s3.amazonaws.com/roxie-marketing-site/horizontal_configurator/horizontal_configurator.js');
    this.createScriptNode('https://joinroxie.s3.amazonaws.com/roxie-marketing-site/vertical_configurator/vertical_configurator.js');
  },
  methods: {
    createScriptNode(url) {
      const recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', url);
      document.head.appendChild(recaptchaScript);
    }
  }
}
</script>

<style lang="scss">
.product-page-tabs {
  .v-tabs-slider-wrapper {
    height: 6px !important;
  }

  .v-tabs-slider {
    border-radius: 6px;
  }

  .v-tabs-bar {
    height: 70px;
  }

  .v-tab {
    padding-top: 16px;
    padding-bottom: 16px;
    font-size: 24px;
  }

  &.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
    color: #000 !important;
  }
}

.product-details {
  position: relative;
  width: 100%;
  max-width: 952px;
  border: 1px solid #F20D55;
  border-radius: 5px;

  h3 {
    position: absolute;
    top: -21px;
    left: -24px;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 2px;
    background-color: #fff;
  }

  .product-details-item {
    h6 {
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 2px;
    }

    img {
      width: 20px;
      height: 21px;
    }

    &.metals {
      width: 200px;
      margin-right: 60px;
      font-size: 14px;
    }

    &.stones {
      width: 200px;
      margin-right: 80px;
      font-size: 9px;

      .stone-item {
        width: 50px;
      }
    }

    &.sizes {
      width: 160px;
      font-size: 16px;
      line-height: 19px;
    }

    &.other {
      width: 186px;
      font-size: 16px;
      line-height: 19px;
      & > div:last-child {
        margin-top: 64px;
      }
    }
  }
}

#vertical-flight-template {
  display: none !important;
}

.vertical-theme {
  #horizontal-slide-template {
    display: none !important;
  }

  #vertical-flight-template {
    display: block !important;
  }
}


.customize-product {
  height: auto !important;
}

.bj-customizer-horizontal-pendant-flow .left-section .product-image {
  height: 480px;
}

@media screen and (max-width: 980px) {
  .product-page-tabs {
    .v-tab {
      padding-top: 8px;
      padding-bottom: 8px;
      font-size: 16px;
    }

    .v-tabs-bar {
      height: 48px;
    }

    .v-tabs-slider-wrapper {
      height: 4px !important;
    }
  }

  .product-details {
    flex-wrap: wrap;
    max-width: 540px;
    .product-details-item {
      &.metals,
      &.stones {
        width: 50%;
        margin: 0;
      }

      &.sizes {
        width: 24%;
      }

      &.other {
        width: 76%;
        display: flex;
        justify-content: space-between;
        & > div:last-child {
          margin-top: 0;
        }
        h6 {
          margin-bottom: 20px!important;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .product-details {
    max-width: calc(100% - 32px);
    .product-details-item {
      &.metals,
      &.stones {
        width: 100%;
      }

      &.sizes {
        width: 100%;
      }

      &.other {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        & > div:last-child {
          margin-top: 24px;
        }
      }
    }
  }
}

@media screen and (max-width: 475px) {
  .product-page-tabs {
    .v-tab {
      padding: 8px;
      font-size: 11px;
    }

    .v-tabs-bar {
      height: 48px;
    }

    .v-tabs-slider-wrapper {
      height: 2px !important;
    }
  }
}
</style>
