<template>
  <div class="faq mx-auto px-4 faq-flex">
    <h2 class="section-header text-center faq-flex__title">FAQ</h2>

    <v-expansion-panels
      accordion
      flat
      multiple
      class="faq-accordion"
    >
      <v-expansion-panel>
        <v-expansion-panel-header class="pa-0">
          <span class="d-inline-block flex-grow-0 flex-shrink-0 mr-4"></span>
          Is Roxie really free?
        </v-expansion-panel-header>
        <v-expansion-panel-content>Yes! Roxie was created to open up the “old market” of jewelry to ambitious
          entrepreneurs like yourself. We are here to support your business goals.
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="pa-0">
          <span class="d-inline-block flex-grow-0 flex-shrink-0 mr-4"></span>
          When can I begin selling?
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          ASAP! You can begin selling as soon as you:
          <ul class="pa-0 ma-0 mt-2" style="list-style-type: none">
            <li class="pl-4 mb-2">1. Install the Roxie app.</li>
            <li class="pl-4 mb-2">2. Add your desired products to your e-store.</li>
            <li class="pl-4">3. Set your desired profit margin.</li>
          </ul>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="pa-0">
          <span class="d-inline-block flex-grow-0 flex-shrink-0 mr-4"></span>
          How much freedom do I have in building my ecommerce store?
        </v-expansion-panel-header>
        <v-expansion-panel-content>You have so much freedom! We offer a variety of configurator themes and designs,
          and you have the freedom to choose which bespoke jewelry pieces you sell.
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="pa-0">
          <span class="d-inline-block flex-grow-0 flex-shrink-0 mr-4"></span>
          Can I trust that Roxie will provide highest jewelry quality?
        </v-expansion-panel-header>
        <v-expansion-panel-content>Yes! Our designers and production teams work hard to craft top-notch, genuine
          pieces—our clients and their customers agree!
          <router-link :to="{name: 'MainPageLanding', hash: '#testimonials'}" class="font-weight-regular text-decoration-underline">Testimonials</router-link>.
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="pa-0">
          <span class="d-inline-block flex-grow-0 flex-shrink-0 mr-4"></span>
          Will each piece be personalized and handcrafted?
        </v-expansion-panel-header>
        <v-expansion-panel-content>Yes! Our designers and production teams work hard to handcraft top-notch, genuine pieces. Visit our
          <template v-if="$route.name !== 'CatalogLanding'">
            <router-link :to="{name: 'CatalogLanding'}" class="font-weight-regular text-decoration-underline">Catalog</router-link>
          </template>
          <template v-else>
            <a @click="scrollTop" class="text-decoration-underline">Catalog</a>
          </template>
          for a preview of the premium jewelry we offer on the Roxie app!
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="pa-0">
          <span class="d-inline-block flex-grow-0 flex-shrink-0 mr-4"></span>
          Where can I find Roxie’s jewelry catalog?
        </v-expansion-panel-header>
        <v-expansion-panel-content>You can find a preview of some of Roxie’s extensive catalog of top-notch, premium jewelry

          <template v-if="$route.name !== 'CatalogLanding'">
            <router-link :to="{name: 'CatalogLanding'}" class="font-weight-regular text-decoration-underline">here</router-link>!
          </template>
          <template v-else><a @click="scrollTop" class="text-decoration-underline">here</a>!</template>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  name: 'FAQ',
  methods: {
    scrollTop() {
      window.scrollTo(0, 0);
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/colors";

.faq {
  padding-top: 106px;
  max-width: 858px;
  width: 100%;

  .faq-accordion {
    * {
      font-family: Cochin-LT, sans-serif !important;
    }

    .v-expansion-panel {
      margin-top: 58px;
    }

    .v-expansion-panel-header__icon {
      display: none;
    }

    .v-expansion-panel-header {
      font-size: 24px;
      line-height: 1.1;

      span {
        position: relative;
        width: 24px;
        height: 24px;
        transition: transform .3s ease-in;

        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: $primary;
        }

        &::before {
          width: 100%;
          height: 2px;
        }

        &::after {
          width: 2px;
          height: 100%;
          transition: opacity .3s ease-in;
        }
      }
    }

    .v-expansion-panel-header--active {
      span {
        transform: rotate(180deg);

        &::after {
          opacity: 0;
        }
      }
    }

    .v-expansion-panel-content__wrap {
      padding: 12px 0 0;
      font-size: 18px;
      line-height: 1.38;
      a {
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.faq-flex {
  display: flex;
  gap: 76px;
  &__title {
    font-family: 'Cochin', sans-serif;
    font-weight: bold;
    font-size: 48px;
    line-height: 56px;
    color: #333333;
    transform: translateY(50px);
    position: relative;
    &::before {
      content: '';
      background: transparent url("~@/assets/img/landings/how-it-works/draw_brush.svg") no-repeat center center;
      position: absolute;
      width: 119px;
      height: 11px;
      right: -5px;
      top: 60px;
    }
  }
}

@media screen and (max-width: 767px) {
  .faq {

    .faq-accordion {
      .v-expansion-panel-header {
        font-size: 28px;
      }

      .v-expansion-panel-content__wrap {
        font-size: 14px;
      }
    }
  }
  .faq-flex {
    flex-direction: column;
    &__title {
      transform: translateY(0);
      &::before {
        content: '';
        background: transparent url("~@/assets/img/landings/how-it-works/draw_brush.svg") no-repeat center center;
        position: absolute;
        width: 119px;
        height: 11px;
        transform: translateX(50%);
        right: 50%;
        top: 60px;
      }
    }
  }
}
</style>
